import { Feature, Geometry } from 'geojson';
import mapboxgl, {
  GeoJSONSource,
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';
import { useLocale } from 'next-intl';
import { MutableRefObject, useEffect } from 'react';

import { Bounds, CameraMapOptions, MapItem } from '@/components/map/types';
import useSearchPageParams from '@/components/search/useSearchPageParams';
import { CURRENT_LOCATION_VALUE } from '@/components/search/utils';
import { MAP_STYLE } from '@/utils';
import {
  getArrayOfKeyRenderedFeaturesWithinView,
  getUniqueFeatures,
} from '@/utils/mapbox';

const SOURCE_ID = 'industrious-locations';
const CLUSTERS_LAYER_ID = 'clusters';
const UNCLUSTERS_LAYER_ID = 'unclustered-points';
const ANIMATION_DURATION = 1000;
const CLUSTER_MAX_ZOOM = 10;
const MAX_ZOOM_POPUP_CLUSTERS = 12;

export type OnMoveEndProps = {
  bounds: Bounds;
  center: LngLat;
  zoom?: number;
  shouldNotUpdateQueryParams?: boolean;
};
export type OnZoomChangeProps = OnMoveEndProps & {
  isZoomIn: boolean;
  visibleSlugs: string[];
};

type UseMapProps<T extends MapItem> = {
  features: Feature<Geometry, T>[];
  haveParamsWithCoords: boolean;
  initialCameraOptions: CameraMapOptions;
  map: MutableRefObject<mapboxgl.Map | null>;
  mapboxAccessToken: string;
  mapContainer: MutableRefObject<null>;
  minZoom?: number;
  maxZoom?: number;
  clusterPerLocation?: boolean;
  staticMapRef: MutableRefObject<HTMLDivElement | null>;
  onMarkerClicked?: (props: T | T[] | undefined) => void;
  onZoomChange?: (props: OnZoomChangeProps) => void;
  onMoveEnd?: (props: OnMoveEndProps) => void;
};

export function useMap<T extends MapItem>({
  features,
  haveParamsWithCoords,
  initialCameraOptions,
  map,
  mapboxAccessToken,
  mapContainer,
  minZoom,
  maxZoom,
  clusterPerLocation,
  staticMapRef,
  onMarkerClicked,
  onZoomChange,
  onMoveEnd,
}: UseMapProps<T>) {
  const locale = useLocale();
  const { queryParams } = useSearchPageParams();

  useEffect(() => {
    if (queryParams.query === CURRENT_LOCATION_VALUE) {
      addCurrentPositionMarker();
    }
  }, [queryParams.query]);

  useEffect(() => {
    if (!map.current) return;
    refreshSource(map.current);
  }, [features]);

  const getInitialCenter = () => {
    const centerParam = queryParams.center as string;
    const boundsParam = queryParams.bounds as string;

    if (haveParamsWithCoords) {
      const queryZoom = +(queryParams.zoom as string);
      return {
        ...(boundsParam && {
          bounds: boundsParam.split(',') as unknown as LngLatBoundsLike,
        }),
        center: {
          lng: +centerParam.split(',')[0],
          lat: +centerParam.split(',')[1],
        },
        zoom: minZoom ? Math.max(queryZoom, minZoom) : queryZoom,
      };
    }

    return {
      center: initialCameraOptions.center,
      zoom: initialCameraOptions.zoom,
      bounds: initialCameraOptions.bounds,
    };
  };

  const initializeMap = () => {
    if (!mapContainer.current) return;

    mapboxgl.accessToken = mapboxAccessToken;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${MAP_STYLE}`,
      minZoom,
      maxZoom: maxZoom || undefined,
      ...getInitialCenter(),
    });

    map.current.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'top-left'
    );

    map.current.on('load', () => {
      map.current?.setLayoutProperty('country-label', 'text-field', [
        'get',
        `name_${locale}`,
      ]);

      addSourceIntoMap();
      addClusters();

      handleClickMap();
      handleClusterHover();
      handleMarkerHover();

      if (queryParams.query === CURRENT_LOCATION_VALUE) {
        addCurrentPositionMarker();
      }

      map.current?.once('idle', () => {
        if (staticMapRef.current) {
          staticMapRef.current.style.visibility = 'hidden';
        }
      });

      if (onZoomChange) {
        handleZoomChange();
        map.current?.on('zoomend', (e) => {
          handleZoomChange(isZoomIn(e as IsZoomInProps));
        });
      }

      map.current?.on('moveend', (event) => {
        if (
          !map.current ||
          !onMoveEnd ||
          !event.originalEvent ||
          event.originalEvent.type === 'wheel'
        )
          return;

        onMoveEnd({
          center: map.current.getCenter(),
          bounds: mapLngLatBounds(map.current.getBounds()),
          zoom: map.current.getZoom(),
          shouldNotUpdateQueryParams: event.shouldNotUpdateQueryParams || false,
        });
      });
    });
  };

  const addCurrentPositionMarker = () => {
    const centerParam = queryParams.center as string;
    if (map.current?.getSource('points')) return;
    map.current?.loadImage(
      '../icons/markerCurrentPosition.png',
      (error, image) => {
        if (error || !image) throw error;
        map.current?.addImage('custom-marker', image);

        map.current?.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [
                    +centerParam.split(',')[0],
                    +centerParam.split(',')[1],
                  ],
                },
                properties: {},
              },
            ],
          },
        });

        map.current?.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'points',
          layout: {
            'icon-size': 0.7,
            'icon-image': 'custom-marker',
          },
        });
      }
    );
  };

  const addSourceIntoMap = () => {
    map.current?.addSource(SOURCE_ID, {
      type: 'geojson',
      cluster: true,
      ...(!clusterPerLocation && {
        clusterMaxZoom: CLUSTER_MAX_ZOOM,
      }),
      generateId: true,
      data: {
        type: 'FeatureCollection',
        features,
      },
    });
  };

  const refreshSource = (map: mapboxgl.Map) => {
    (map.getSource(SOURCE_ID) as GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features,
    });
  };

  const addClustersCountText = () => {
    map.current?.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: SOURCE_ID,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });
  };

  const addClusters = () => {
    map.current?.addLayer({
      id: CLUSTERS_LAYER_ID,
      type: 'circle',
      source: SOURCE_ID,
      filter: ['has', 'point_count'],
      paint: {
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step
        //   * 15px circles when point count is less than 10
        //   * 22px circles when point count is between 10 and 20
        //   * 30px circles when point count is greater than or equal to 20
        'circle-color': '#002D2D',
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['step', ['get', 'point_count'], 20, 10, 25, 20, 35],
          ['step', ['get', 'point_count'], 15, 10, 22, 20, 30],
        ],
        'circle-radius-transition': { duration: 5000 },
      },
    });

    map.current?.addLayer({
      id: UNCLUSTERS_LAYER_ID,
      type: 'circle',
      source: SOURCE_ID,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#FDD344',
          '#002D2D',
        ],
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          7,
          8,
        ],
        'circle-stroke-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          8,
        ],
        'circle-stroke-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#002D2D',
          'transparent',
        ],
      },
    });

    addClustersCountText();
  };

  // helper to get the rendered features in the map
  function getRenderedFeatureByLayerId(params: {
    point?: mapboxgl.Point;
    layerId: string | string[];
  }) {
    const { point, layerId } = params;

    return map.current?.queryRenderedFeatures(point, {
      layers: Array.isArray(layerId) ? layerId : [layerId],
    });
  }

  const handleClickMap = () => {
    map.current?.on('click', (e) => {
      const features = getRenderedFeatureByLayerId({
        point: e.point,
        layerId: [CLUSTERS_LAYER_ID, UNCLUSTERS_LAYER_ID],
      });

      if (!features) return;

      if (features?.length === 0) {
        onMarkerClicked?.(undefined);
        highlightPointOnMap(undefined);
        return;
      }

      if (features[0].layer.id === UNCLUSTERS_LAYER_ID) {
        onMarkerClicked?.(features[0].properties as T);
        highlightPointOnMap(features[0].properties?.slug);
        return;
      }

      handleClusterClick(features[0]);
    });
  };

  const handleClusterClick = (feature: mapboxgl.MapboxGeoJSONFeature) => {
    const clusterId = feature.properties?.cluster_id;

    const source = map.current?.getSource(SOURCE_ID) as mapboxgl.GeoJSONSource;
    const zoom = map.current?.getZoom();

    if (clusterPerLocation) {
      if (
        zoom &&
        zoom < MAX_ZOOM_POPUP_CLUSTERS &&
        feature.geometry.type === 'Point'
      ) {
        map.current?.easeTo({
          center: [
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ],
          zoom: zoom + 3,
          duration: ANIMATION_DURATION,
        });
      } else {
        source.getClusterLeaves(
          clusterId,
          feature.properties?.point_count,
          0,
          function (err, aFeatures) {
            if (!onMarkerClicked) return;
            onMarkerClicked(aFeatures.map((a) => a.properties) as T[]);
            highlightPointOnMap(aFeatures[0].properties?.slug);
          }
        );
      }
    } else {
      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        if (feature.geometry.type === 'Point') {
          map.current?.easeTo({
            center: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ],
            zoom,
            duration: ANIMATION_DURATION,
          });
        }
      });
    }
  };

  const handleClusterHover = () => {
    if (!map.current) return;
    const currentMap = map.current;

    currentMap.on('mouseenter', CLUSTERS_LAYER_ID, () => {
      currentMap.getCanvas().style.cursor = 'pointer';
    });
    currentMap.on('mouseleave', CLUSTERS_LAYER_ID, () => {
      currentMap.getCanvas().style.cursor = '';
    });
  };

  const handleMarkerHover = () => {
    if (!map.current) return;
    const currentMap = map.current;

    currentMap.on('mouseenter', UNCLUSTERS_LAYER_ID, () => {
      currentMap.getCanvas().style.cursor = 'pointer';
    });
    currentMap.on('mouseleave', UNCLUSTERS_LAYER_ID, () => {
      currentMap.getCanvas().style.cursor = '';
    });
  };

  const handleZoomChange = async (isZoomIn?: boolean) => {
    if (map.current && onZoomChange) {
      const visibleSlugs = await getVisiblePointSlugs();
      onZoomChange({
        center: map.current.getCenter(),
        bounds: mapLngLatBounds(map.current.getBounds()),
        isZoomIn: isZoomIn || false,
        zoom: map.current.getZoom(),
        visibleSlugs,
      });
    }
  };

  const getVisiblePointSlugs = async () => {
    const source = map.current?.getSource(SOURCE_ID) as mapboxgl.GeoJSONSource;
    const allRenderedFeatures = getRenderedFeatureByLayerId({
      layerId: [CLUSTERS_LAYER_ID, UNCLUSTERS_LAYER_ID],
    });

    if (allRenderedFeatures && allRenderedFeatures.length > 0) {
      const uniqueRenderedFeatures = getUniqueFeatures(allRenderedFeatures);
      return getArrayOfKeyRenderedFeaturesWithinView(
        source,
        uniqueRenderedFeatures,
        'slug'
      );
    }
    return [];
  };

  const highlightPointOnMap = (slug: string | undefined) => {
    map.current?.removeFeatureState({
      source: SOURCE_ID,
    });

    if (slug !== undefined) {
      const allRenderedFeatures = getRenderedFeatureByLayerId({
        layerId: [CLUSTERS_LAYER_ID, UNCLUSTERS_LAYER_ID],
      });

      if (allRenderedFeatures && allRenderedFeatures.length > 0) {
        const uniqueRenderedFeatures = getUniqueFeatures(allRenderedFeatures);

        const clusterSource = map.current?.getSource(
          SOURCE_ID
        ) as mapboxgl.GeoJSONSource;
        uniqueRenderedFeatures.forEach((v) => {
          if ((v as MapboxGeoJSONFeature).layer.id === UNCLUSTERS_LAYER_ID) {
            if (v.properties?.slug === slug) {
              map.current?.setFeatureState(
                {
                  source: SOURCE_ID,
                  id: v.id,
                },
                {
                  hover: true,
                }
              );
            }
          } else {
            clusterSource?.getClusterLeaves(
              v.properties?.cluster_id,
              v.properties?.point_count,
              0,
              (error, features) => {
                const allSlugsOfCluster =
                  features?.map((f) => f.properties?.slug) || [];
                if (allSlugsOfCluster.includes(slug)) {
                  map.current?.setFeatureState(
                    {
                      source: SOURCE_ID,
                      id: v.id,
                    },
                    {
                      hover: true,
                    }
                  );
                }
              }
            );
          }
        });
      }
    }
  };

  return {
    animationDuration: ANIMATION_DURATION,
    initializeMap,
    highlightPointOnMap,
  };
}

function mapLngLatBounds(bounds: LngLatBounds): Bounds {
  return {
    ne: bounds.getNorthEast(),
    sw: bounds.getSouthWest(),
  };
}

type IsZoomInProps = {
  originalEvent?: { deltaY?: number };
};

function isZoomIn(event: IsZoomInProps) {
  const deltaY = event.originalEvent?.deltaY;

  if (!deltaY) return false;

  return deltaY < 0;
}
